import { globalEnvironment } from "./globalEnvironment";
import { labelsCatasia } from "../app/resource/labels";

//const baseURL = "https://administrativo.catastrogarzon.com.co:8443";
const baseURL = "";

/**
 * Garzon Prod
 */
export const environment = {
  environment: "garzon",
  production: true,
  name: "garzon",
  host: globalEnvironment,
  API_URL: `${baseURL}/api/private/`,
  API_LOGIN_URL: `${baseURL}/api/security/oauth/`,
  labels: labelsCatasia["version2.0"],

  //GUI
  theme: "green",
  logoLogin: "assets/layout/images/garzon/login.png",
  loginText: null,
  logoTopBar: "assets/layout/images/garzon/escudo.png",
  textTopBar: null,
  logofooterEsri: null,
  logofooterCatasIA: null,
  logofooterEsriHeight: null,
  logofooterCatasIAHeight: null,
  logoLoginHeight: "250px",
};
